.centerForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkSize {
  width: 18px;
  margin-right: 5px;
}

.formLabel {
  margin-top: 30px;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
}
.formLabelPopoup {
  margin-top: 20px;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
}

.blue {
  background: #0c7dbb;
}

.gray {
  background: #757575;
}

.center {
  justify-content: center;
}

.yellowButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
}

.yellowButton:hover {
  background: #fbab18;
}
@media only screen and (max-width: 600px) {
  .yellowButton {
    width: 90%;
  }
}
.input {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 320px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.input:active,
.input:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}

.inputpopupInputMobileItem:active,
.inputpopupInputMobileItem:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputpopupInputMobileItem {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 80%;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.popupInputHalf:active,
.popupInputHalf:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.popupInputHalf {
  width: 153px;
}

.errorpopupInputHalf {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 153px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.errorpopupInputHalf:active,
.errorpopupInputHalf:focus {
  border: 2.5px solid #e2231a;
}
.errorpopupInputMobileItem {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 50%;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.popupInputMobile:active,
.popupInputMobile:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.popupInputMobile {
  width: 90%;
}

.popupInputMobileItem:active,
.popupInputMobileItem:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.popupInputMobileItem {
  width: 80%;
}

.twoFields {
  margin-right: 12px;
}

.popup {
  padding-left: 10px;
  padding-right: 10px;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.inputMail:active,
.inputMail:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputMail {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 375px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.inputMailMobile:active,
.inputMailMobile:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputMailMobile {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 320px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.inputCity:active,
.inputCity:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputCity {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 245px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.select {
  border: 1px solid #4f4f4f;
}
.selectError {
  border: 2px solid #e2231a;
}
.errorInputCity {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 245px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.errorInputCity:active,
.errorInputCity:focus {
  outline: none;
  border: 2.5px solid #e2231a;
}
.errorPopupTextarea {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
}
.errorTextarea {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
}
.errorTextarea:active,
.errorTextarea:focus {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
}

.amount:active,
.amount:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.amount {
  border: 1px solid #4f4f4f;
  border-radius: 0px;
}
.amountError {
  border: 2.5px solid #e2231a;
  border-radius: 10px;
}
.textarea:active,
.textarea:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.textarea {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
}

.divideLine {
  width: 2px;
  min-width: 2px;
  height: auto;
  min-height: 80vh;
  background: #e6e7e8;
}

.errorRadio {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 250px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.inputSmall:active,
.inputSmall:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputSmall {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.inputSmallState:active,
.inputSmallState:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
.inputSmallState {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 113px;
  height: 43px;
  padding-left: 12px;
  padding-right: 12px;
}
.errorInputSmall {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.errorInputSmall:active,
.errorInputSmall:focus {
  outline: none;
  border: 2.5px solid #e2231a;
}
.errorPopupInputMobile {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 255px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.errorInput {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 320px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.errorInputMail {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 363px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.errorInputMail:active,
.errorInputMail:focus {
  border: 2.5px solid #e2231a;
}
.errorInputMailNoWidth {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.errorInputMailNoWidth:active,
.errorInputMailNoWidth:focus {
  border: 2.5px solid #e2231a;
  background-color: #ffffff;
  outline: none;
}
.errorInput:active,
.errorInput:focus {
  border: 2.5px solid #e2231a;
  outline: none;
  background-color: #ffffff;
}

.errorInputNoWidth {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.errorInputNoWidth:active,
.errorInputNoWidth:focus {
  border: 2.5px solid #e2231a;
  outline: none;
  background-color: #ffffff;
}

.errorText {
  color: #e2231a;
  margin-top: 8px;
  font-size: 16px;
}

.absolute {
  position: absolute;
}
.row {
  display: flex;
}

.tooltip {
  margin-bottom: -5px;
  margin-left: 8px;
}

.div {
  color: #1b1c1d;
}

.header {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 70px;
}
@media only screen and (max-width: 600px) {
  .header {
    margin-bottom: 30px;
    margin-top: 50px;
  }
}
.subheader {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 0px;
}

.subheaderPopup {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.tablesubheader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.mobilesubheader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 20px;
  line-height: 28px;
  font-size: 16px;
}

.TextField[type="number"]::-webkit-inner-spin-button,
.TextField[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#fileUpload {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
}

#customButton {
  border: 1px solid black;
  width: 43%;
}

.icon {
  /* padding-right: 25px; */
  background: url("../../src/Assets/Upload.svg") no-repeat right;
  background-size: 20px;
}

.left {
  width: 79%;
}

.right {
  width: 25%;
  margin-right: 34%;
}

.yellowSessionButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
}

@media only screen and (max-width: 600px) {
  .secondButtonDiv {
    display: flex;
    width: 99%;
    justify-content: center;
  }
}
.secondButtonDiv {
  margin-top: 70px;
}

textarea {
  font-family: "Arial", sans-serif;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  font-size: 16px;
}
textarea:active,
textarea:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}
input {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.label {
  font-size: 18px;
  font-weight: 700;
}

.blueSessionButton {
  height: 40px;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid #07679b;
  border-radius: 4px;
  background-color: transparent;
  color: #07679b;
  font-weight: 700;
  cursor: pointer;
  margin-right: 5%;
}
.witnessButtonPadding {
  margin-bottom: 30px;
}
.blueButton {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #07679b;
  border-radius: 4px;
  background-color: transparent;
  color: #07679b;
  font-weight: 700;
  cursor: pointer;
}

.disableButton {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px;
  border-radius: 4px;
  background-color: rgba(117, 117, 117, 0.4);
  color: #4f4f4f;
  font-weight: 700;
}

.blueButton:active,
.blueButton:hover {
  background: #fbbb36;
  color: black;
  border: 1px solid #fbbb36;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.footerText {
  text-decoration-line: underline;
  color: #07679b;
}
@media only screen and (max-width: 700px) {
  .footerText {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .mobileBar {
    padding-left: 15px;
  }
}

.contentPadding {
  margin-top: 140px;
}

@media only screen and (max-width: 900px) {
  .contentPadding {
    margin-top: 0px;
  }
}

a:hover,
a:active {
  color: #0c7dbb;
}
.backbutton {
  color: #0c7dbb;
  text-decoration: underline;
  border: none;
  background: white;
  cursor: pointer;
  font-size: 18px;
}

.secondButton {
  color: #07679b;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  background: none;
  font-size: 17px;
  padding: 0;
}

.secondButton:active,
.secondButton:hover {
  color: #19335e;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.footerContainer {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  background-color: #f4f5f6;
}

.footerLinks {
  justify-content: space-between;
  margin-bottom: 30px;
}

.footerLinksPadding {
  margin-bottom: 20px;
}

.sessionTimeoutAlert {
  margin-left: 24px;
  margin-top: 20px;
  margin-right: 40px;
}

.sessionTimeoutDescription {
  margin-left: 24px;
  margin-right: 40px;
  line-height: 26px;
}

.sessionTimeoutDeleted {
  margin-left: 24px;
  line-height: 26px;
}

.sessionTimeoutButtons {
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 20px;
}

.sessionTimeoutOk {
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.sessionTimeoutOkButton {
  margin-right: 24px;
  width: full;
}

.editButton {
  margin-right: 10px;
  margin-top: 5px;
  border: none;
  background: none;
  cursor: pointer;
}
.css-1i3s3ua:focus {
  background-color: white;
  outline: 2px solid rgba(0, 0, 0, 0.6);
}

.infoBanner {
  margin-top: 70px;
  font-size: 14px;
}

.input-icon {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 40px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 31px;
  padding-right: 0;
}

.adornedInput {
  width: 315px !important;
}

.adornedInputPopup {
  width: 120px !important;
}
