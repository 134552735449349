.fileName {
  margin-top: 11px;
  position: absolute;
  padding-left: 10px;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closeFile {
  margin-left: 5px;
  margin-top: -20px;
  position: absolute;
  cursor: pointer;
}

.deleteFile {
  color: #07679b;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 2px;
}

.inputDescription {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}

.yellowLoadingButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
  justify-content: center;
  width: 140px;
}

@media only screen and (max-width: 600px) {
  .yellowLoadingButton {
    width: 90%;
  }
}

.onLoad {
  background: #feedc7;
}

.imageUploader {
  border: transparent;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  align-items: center;
  padding-right: 10px;
  padding-left: 300px;
  margin-top: 10px;
  cursor: pointer;
  justify-content: center;
}

.upload {
  opacity: 0;
  position: absolute;
  border-radius: 100px;
  cursor: pointer;
}

.upload:focus + .imageUploader {
  border: 1.5px solid #0c7dbb;
}
